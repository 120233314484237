// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Lifting state only tracks manual overrides.
//
// Outside of overrides, the state of the meet is fully-calculated by the LiftingView.
//
// For safety, correctness, and ease of understanding, the state of the meet is
// intentionally *not* stored in the global state. It is continuously recalculated.
//
// Please do not attempt to store meet state in the Redux store!

import {
  TableChangeAction,
  JuryChangeAction,
  OverwriteStoreAction,
  UpdateChangeAction,
  ClearChangesAction,
} from "../types/actionTypes";
import { Change } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: Array<Change> = []

type Action =
  | TableChangeAction
  | JuryChangeAction
  | UpdateChangeAction
  | ClearChangesAction
  | OverwriteStoreAction;

export default (state: Array<Change> = initialState, action: Action): Array<Change> => {

  console.log('changeReducer', action)

  switch (action.type) {

    case "TABLE_CHANGE":
    {
      const change: Change = {
        id: action.id,
        entryId: action.entryId,
        processed: false,
        source: 'TABLE',
        attemptOneIndexed: action.attemptOneIndexed,
        fieldKgName: action.fieldKgName,
      }
      let changes = state.slice();
      changes.push(change)
      return [
        ...changes,
      ];
    }
  
    case "JURY_CHANGE": 
    {
      const change: Change = {
        id: action.id,
        entryId: action.entryId,
        processed: false,
        source: 'JURY',
        attemptOneIndexed: action.attemptOneIndexed,
        fieldStatusName: action.fieldStatusName,
        status: action.status,
      }
      let changes = state.slice();
      changes.push(change)
      return [
        ...changes,
      ];
    }

    case "UPDATE_CHANGE": 
    {
      let changes = state.slice();
      const changeIndex = changes.findIndex(c => c.id === action.id)
      changes[changeIndex].processed = true
      return [
        ...changes,
      ];
    }

    case "CLEAR_CHANGES": 
    {
      let changes = state.slice();
      for (let change of changes) {
        change.processed = true
      }
      return [
        ...changes,
      ];
    }

    case "OVERWRITE_STORE":
    {
      if (action.store.changes === undefined) return initialState
      return action.store.changes;
    }

    default:
      checkExhausted(action);
      return state;
  }
};
