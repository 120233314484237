// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The parent component of the Leaders page, contained by the LeadersContainer.

import React from "react";
import { connect } from "react-redux";

import { Entry, Equipment, Language, Lift, LiftStatus, Sex } from "../../types/dataTypes";
import { GlobalState, LiftingState, MeetState, RegistrationState, ServerState } from "../../types/stateTypes";

import styles from "./LeadersView.module.scss";
import { checkFlightLiftStarted, getEntriesInFlight, getEntriesInSession, getLastFlightAttempt, getLiftingOrder } from "../../logic/liftingOrder";
import { getFinalTotalKg, getPrognosisTotalKg, liftToAttemptFieldName, liftToStatusFieldName, mapSexToClasses } from "../../logic/entry";
import { getFlightRanktypeEntries, RankTypeEntries } from "../../logic/pointsPlace";
import { displayPoints, displayWeight, displayWeightOnePlace, kg2lbs } from "../../logic/units";
import { getWeightClassStr } from "../../reducers/meetReducer";
import DataTable, { BACKGROUNDCOLOR, getTitleWidth, TableEntry } from "../overlays/DataTable";
import { getDataPositions, getTableData, getTestImage } from "../../logic/config";
import { sortByPlace } from "../../logic/divisionPlace";
import { goodlift } from "../../logic/coefficients/goodlift";
import { compareWeightClasses } from "../../logic/records/records";

const notRanked = 998
const rankGuest = 999

const maxViewableEntries = 20

interface RankTypeEntriesMax extends RankTypeEntries {
  maxEntries: number;
}

export interface EntryInfo {
  entryId: number;
  name: string;
  bodyweight: number;
  lot: number;
  guest: boolean;
  nameBackground: BACKGROUNDCOLOR;
  squatKg: Array<number>;
  benchKg: Array<number>;
  deadliftKg: Array<number>;
  squatStatus: Array<LiftStatus>;
  benchStatus: Array<LiftStatus>;
  deadliftStatus: Array<LiftStatus>;
  prognosisTotal: number;
  prognosisTotalRank: number;
  finalTotal: number;
  finalTotalRank: number;
  prognosisPoints: number;
  prognosisPointsRank: number;
  finalPoints: number;
  finalPointsRank: number;
}

interface StateProps {
  meet: MeetState;
  lifting: Array<LiftingState>,
  registration: RegistrationState,
  server: ServerState,
  language: Language;
}

interface OwnProps {
  height?: number;
  bare?: boolean;
}

type Props = StateProps & OwnProps;

class ObsLeadersView extends React.Component<Props> {
 
  getWeight = (weightKg: number): string => {
    if (weightKg === 0) return ''
    const weight = displayWeightOnePlace(weightKg, this.props.language);
    return weight;
  };

  getPoints = (points: number): string => {
    if (points === 0) return ''
    const p = displayPoints(points, this.props.language);
    return p;
  };

  getWeightClass = (sex: Sex, fClassesForSex: readonly number[], mClassesForSex: readonly number[], bodyweightKg: number): string => {
    const classesForSex = sex === 'F' ? fClassesForSex : mClassesForSex
    const weightClassStr = getWeightClassStr(classesForSex, bodyweightKg);
    return weightClassStr
  }

  getTotalTableEntry = (entry: EntryInfo): TableEntry[] => {
    const row: TableEntry[] = []
    const finalRank = entry.finalTotalRank === notRanked ? '' : (entry.finalTotalRank === rankGuest ? 'G' : entry.finalTotalRank.toString())
    const prognosisRank = entry.prognosisTotalRank === notRanked ? '' : (entry.prognosisTotalRank === rankGuest ? ' [G]' : ' [' + entry.prognosisTotalRank.toString() + ']')
    row.push({data: finalRank, backgroundColor: BACKGROUNDCOLOR.COUNT})
    row.push({data: entry.name, backgroundColor: entry.nameBackground})

    row.push({data: this.getPoints(entry.bodyweight), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: entry.lot.toString(), backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    for (const lift of ['S', 'B', 'D']) {
      let fieldKg = liftToAttemptFieldName(lift as Lift);
      let fieldStatus = liftToStatusFieldName(lift as Lift);
      for (let attempt = 0; attempt < 3; attempt++) {
        const kg = entry[fieldKg][attempt];
        const entryWeight = this.getWeight(kg);
        const entryStatus = entry[fieldStatus][attempt];
        let background = BACKGROUNDCOLOR.ATTEMPT
        if (entryStatus === 1) background = BACKGROUNDCOLOR.GOODLIFT
        if (entryStatus === -1) background = BACKGROUNDCOLOR.NOLIFT
        row.push({data: entryWeight, backgroundColor: background})
      }
    }
 
    row.push({data: this.getWeight(entry.finalTotal), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: this.getPoints(entry.finalPoints), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: this.getWeight(entry.prognosisTotal) + prognosisRank, backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    return row
  }

  getPointsTableEntry = (entry: EntryInfo): TableEntry[] => {
    const row: TableEntry[] = []
    const finalRank = entry.finalPointsRank === notRanked ? '' : (entry.finalPointsRank === rankGuest ? 'G' : entry.finalPointsRank.toString())
    const prognosisRank = entry.prognosisPointsRank === notRanked  ? '' : (entry.prognosisPointsRank === rankGuest ? ' [G]' : ' [' + entry.prognosisPointsRank.toString() + ']')
    row.push({data: finalRank, backgroundColor: BACKGROUNDCOLOR.COUNT})
    row.push({data: entry.name, backgroundColor: entry.nameBackground})

    row.push({data: this.getPoints(entry.bodyweight), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: entry.lot.toString(), backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    for (const lift of ['S', 'B', 'D']) {
      let fieldKg = liftToAttemptFieldName(lift as Lift);
      let fieldStatus = liftToStatusFieldName(lift as Lift);
      for (let attempt = 0; attempt < 3; attempt++) {
        const kg = entry[fieldKg][attempt];
        const entryWeight = this.getWeight(kg);
        const entryStatus = entry[fieldStatus][attempt];
        let background = BACKGROUNDCOLOR.ATTEMPT
        if (entryStatus === 1) background = BACKGROUNDCOLOR.GOODLIFT
        if (entryStatus === -1) background = BACKGROUNDCOLOR.NOLIFT
        row.push({data: entryWeight, backgroundColor: background})
      }
    }
 
    row.push({data: this.getWeight(entry.finalTotal), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: this.getPoints(entry.finalPoints), backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    row.push({data: this.getPoints(entry.prognosisPoints) + prognosisRank, backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    return row
  }

  // Returns a copy of the EntryInfo array sorted by Points
  sortByPoints = (entries: EntryInfo[], type: string): EntryInfo[] => {

    // Clone the entries array to avoid modifying the original.
    const clonedEntries = entries.slice();

    // Sort in the given category, first place having the lowest index.
    clonedEntries.sort((a, b) => {

      // If either of the lifters are guests, sort the guest last
      if (a.guest !== b.guest) {
        return Number(a.guest) - Number(b.guest);
      }

      // First sort by points, higher sorting lower.
      if (type === 'Final') {
        if (a.finalPoints !== b.finalPoints) return b.finalPoints - a.finalPoints;
        if (a.prognosisPoints !== b.prognosisPoints) return b.prognosisPoints - a.prognosisPoints;
      } else if (type === 'Prognosis') {
        if (a.prognosisPoints !== b.prognosisPoints) return b.prognosisPoints - a.prognosisPoints;
      }

      // If points are equal, sort by Bodyweight, lower sorting lower.
      if (a.bodyweight !== b.bodyweight) return a.bodyweight - b.bodyweight;

      // Otherwise, they're equal.
      return 0;
    });

    return clonedEntries;
  };

  getEntryById(entryId: number) {
    const idx = this.props.registration.lookup[entryId];
    const entry = this.props.registration.entries[idx];
    return entry;
  }

  getRankTypeEntries(entriesInFlight: Entry[], lift: Lift, attemptOneIndexed: number) {

    const fClassesForSex = mapSexToClasses('F', this.props.meet.weightClassesKgMen, this.props.meet.weightClassesKgWomen, this.props.meet.weightClassesKgMx);
    const mClassesForSex = mapSexToClasses('M', this.props.meet.weightClassesKgMen, this.props.meet.weightClassesKgWomen, this.props.meet.weightClassesKgMx);
  
    const rankTypesEntries: RankTypeEntries[] = getFlightRanktypeEntries(entriesInFlight, this.props.registration.entries, lift, attemptOneIndexed, fClassesForSex, mClassesForSex)

    rankTypesEntries.sort((a,b) => 
      (a.sex.localeCompare(b.sex)) ||
      (b.equipment.localeCompare(a.equipment)) || 
      (compareWeightClasses(a.weightClass, b.weightClass) ||
      a.event.localeCompare(b.event))
    )

    return rankTypesEntries
  }

  getTables(rankTypesEntries: RankTypeEntriesMax[], currentEntry: Readonly<Entry> | undefined, tableData: any, top: number, bare: boolean) {
  
    const entryHeight = tableData.entryHeight
    const entryYGap = tableData.entryYGap
    const entryXGap = tableData.entryXGap

    const dataTables: JSX.Element[] = []

    rankTypesEntries.sort((a,b) => 
      (a.sex.localeCompare(b.sex)) ||
      (b.equipment.localeCompare(a.equipment)) || 
      (compareWeightClasses(a.weightClass, b.weightClass))
    )

    rankTypesEntries.forEach(rankTypesEntry => {

      const interimRankedEntries = rankTypesEntry.interimRankedEntries
      const prognosisRankedEntries = rankTypesEntry.prognosisRankedEntries
      const maxEntries = rankTypesEntry.maxEntries

      // 'SEX_EQUIPMENT_WEIGHTCLASS'
      // 'SEX_EQUIPMENT_POINTS'
      // 'EQUIPMENT_POINTS'
      const rankType = rankTypesEntry.rankType
    
      if (interimRankedEntries === undefined || prognosisRankedEntries === undefined) return

      const entriesByPrognosisTotal = sortByPlace(prognosisRankedEntries, 'SBD', 'Prognosis')
      console.log('entriesByPrognosisTotal', entriesByPrognosisTotal)

      // NOTE - interim always allows a subtotal unless bombed out (if failing a lift that lift won't be included in the subtotal)
      const entriesByInterimTotal = sortByPlace(interimRankedEntries, 'SBD', 'Interim')
      console.log('entriesByInterimTotal', entriesByInterimTotal)

      // create entryInfo from prognosis set so all relevant entries are processed
      let index = 1
      let entryData: EntryInfo[] = []
      entriesByPrognosisTotal.forEach(entry => {
        const entryInfo: EntryInfo = {} as EntryInfo
        entryInfo.entryId = entry.id
        entryInfo.bodyweight = entry.bodyweightKg
        entryInfo.lot = entry.lot
        entryInfo.guest = entry.guest
        entryInfo.name = entry.name
        entryInfo.nameBackground = (currentEntry !== undefined && entry.id === currentEntry.id) ? BACKGROUNDCOLOR.HIGHLIGHT : BACKGROUNDCOLOR.DEFAULT

        entryInfo.squatKg = entry.squatKg
        entryInfo.benchKg = entry.benchKg
        entryInfo.deadliftKg = entry.deadliftKg
        entryInfo.squatStatus = entry.squatStatus
        entryInfo.benchStatus = entry.benchStatus
        entryInfo.deadliftStatus = entry.deadliftStatus

        entryInfo.prognosisTotal = getPrognosisTotalKg(entry)
        entryInfo.finalTotal = getFinalTotalKg(entry)
//        if (rankType === 'SEX_EQUIPMENT_POINTS' || rankType === 'EQUIPMENT_POINTS') {
          entryInfo.prognosisPoints = goodlift(entryInfo.prognosisTotal, entry.bodyweightKg, entry.sex, entry.equipment, entry.events[0]);
          entryInfo.finalPoints = goodlift(entryInfo.finalTotal, entry.bodyweightKg, entry.sex, entry.equipment, entry.events[0]);
//        }
        // guests and notRanked come at the end, index in array is placing otherwise
        if (entry.guest) {
          entryInfo.prognosisTotalRank = rankGuest
        } else if (entryInfo.prognosisTotal === 0) {
          entryInfo.prognosisTotalRank = notRanked
        } else {
          entryInfo.prognosisTotalRank = index
        }
        index += 1
        entryData.push(entryInfo)
      });

      // now add in final total placings
      index = 1
      entriesByInterimTotal.forEach(entry => {
        const entryInfo = entryData.find(e => e.entryId === entry.id)
        if (entryInfo !== undefined) {
          // guests and notRanked come at the end, index in array is placing otherwise
          if (entry.guest) {
            entryInfo.finalTotalRank = rankGuest
          } else if (entryInfo.finalTotal === 0) {
            entryInfo.finalTotalRank = notRanked
          } else {
            entryInfo.finalTotalRank = index
          }
        }
        index += 1
      });
      console.log('entryData', entryData)

      let tableFunction = this.getTotalTableEntry // default for TS
      let columnHeading = ''
      let columnWidth = 0

      if (rankType === 'SEX_EQUIPMENT_WEIGHTCLASS' || rankType === '') {

        // sort entries on final total/prognosis total
        entryData.sort((a,b) => 
          (a.finalTotalRank - b.finalTotalRank) || 
          (a.prognosisTotalRank - b.prognosisTotalRank))

        tableFunction = this.getTotalTableEntry
        columnHeading = 'Total'
        columnWidth = 62

      } else if (rankType === 'SEX_EQUIPMENT_POINTS' || rankType === 'EQUIPMENT_POINTS') {

        // now get the rankings for points
        // prognosis placings are for the full set
        const entriesByPrognosisPoints = this.sortByPoints(entryData, 'Prognosis')
        index = 1
        entriesByPrognosisPoints.forEach(entry => {
          const entryInfo = entryData.find(e => e.entryId === entry.entryId)
          if (entryInfo !== undefined) {
            if (entry.guest) {
              entryInfo.prognosisPointsRank = rankGuest
            } else if (entryInfo.prognosisPoints === 0) {
              entryInfo.prognosisPointsRank = notRanked
            } else {
              entryInfo.prognosisPointsRank = index
            }
            index += 1
          }
        });

        // for interim, placings are against the subset
        let subsetEntryData: EntryInfo[] = []
        if (interimRankedEntries.length === prognosisRankedEntries.length) {
          subsetEntryData = entryData.slice()
        } else {
          interimRankedEntries.forEach(entry => {
            const entryInfo = entryData.find(e => e.entryId === entry.id)
            if (entryInfo !== undefined) {
              subsetEntryData.push(entryInfo)
            }
          })
        }
        console.log('subsetEntryData', subsetEntryData)

        const entriesByFinalPoints = this.sortByPoints(subsetEntryData, 'Final')
        index = 1
        entriesByFinalPoints.forEach(entry => {
          const entryInfo = entryData.find(e => e.entryId === entry.entryId)
          if (entryInfo !== undefined) {
            if (entry.guest) {
              entryInfo.finalPointsRank = rankGuest
            } else if (entryInfo.finalPoints === 0) {
              entryInfo.finalPointsRank = notRanked
            } else {
              entryInfo.finalPointsRank = index
            }
            index += 1
          }
        });

        tableFunction = this.getPointsTableEntry
        columnHeading = 'Points'
        columnWidth = 66
 
        entryData = entriesByFinalPoints
        
      }
      console.log('final entryData', entryData)

      // we want to show the top N, but also the current entry (if flagged) if it's outside the top N
      const checkForEntry = currentEntry !== undefined
      const data: Array<TableEntry[]> = []
      index = 0
      let foundEntry = undefined
      for (const entry of entryData) {
        if (checkForEntry) {
          if (entry.entryId === currentEntry.id) {
            if (index >= maxEntries) {
              foundEntry = entry
            }
          }
        }
        if (index < maxEntries) {
          const row = tableFunction(entry)
          data.push(row)
          index += 1
        }
      }
      if (checkForEntry && foundEntry !== undefined) {
        const row = tableFunction(foundEntry)
        data.push(row)
      } 

      const placingCount = entryData.length > maxEntries ? 'Top ' + maxEntries + ' ' : ''
      const sex = rankTypesEntry.sex === 'F' ? 'Women ' : (rankTypesEntry.sex === 'M' ? 'Men ' : 'Combined ')
      const equipment = rankTypesEntry.equipment === 'Single-ply' ? 'Equipped ' : ''
      const bench = rankTypesEntry.event === 'B' ? 'Bench Only ' : ''
      let placingTitle = ''
      if (rankType === 'SEX_EQUIPMENT_WEIGHTCLASS' || rankType === '') {
        placingTitle = placingCount + equipment + bench + sex + rankTypesEntry.weightClass + ' kg Placings'
      } else if (rankType === 'SEX_EQUIPMENT_POINTS') {
        placingTitle = placingCount + equipment + bench + sex + 'IPF GL Points Placings'
      } else if (rankType === 'EQUIPMENT_POINTS') {
        placingTitle = placingCount + equipment + bench + sex + 'IPF GL Points Placings'
      }

      const title: TableEntry = {data: placingTitle, backgroundColor: BACKGROUNDCOLOR.TITLE}
      const header: TableEntry[] = [
        {data: 'Pos', backgroundColor: BACKGROUNDCOLOR.COUNT},
        {data: 'Name', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'BW', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'Lot', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'S1', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'S2', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'S3', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'B1', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'B2', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'B3', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'D1', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'D2', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'D3', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'Total', backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: columnHeading, backgroundColor: BACKGROUNDCOLOR.HEADER},
        {data: 'Prognosis', backgroundColor: BACKGROUNDCOLOR.HEADER},
      ]
      const aligns = ['center', 'left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']
      const widths = [24, 140, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, columnWidth]
      const titleWidth = getTitleWidth(widths, entryXGap)
      let left = (1920 - titleWidth) / 2
      if (bare) left = 0

      const tableHeight = ((data.length + 2) * entryHeight) + ((data.length + 1) * entryYGap)

      const dataTable =  (
        <DataTable top={top} left={left} title={title} header={header} data={data} widths={widths} aligns={aligns}/>
      )
      dataTables.push(dataTable)
  
      top += tableHeight + entryHeight

    })
    return dataTables
  }

  render() {

    const tableData = getTableData()

    const entryHeight = tableData.entryHeight
    const entryYGap = tableData.entryYGap

    const lifting = this.props.lifting[this.props.server.platform-1];
    const day = lifting.day;
    const session = lifting.session;
    const platform = lifting.platform;
    const flight = lifting.flight;
    const lift = lifting.lift;
    const entries = this.props.registration.entries;
    const entriesInFlight = getEntriesInFlight(day, session, platform, flight, entries);
    const now = getLiftingOrder(entriesInFlight, lifting);
    const attemptOneIndexed = now.attemptOneIndexed < 1 ? 3 : now.attemptOneIndexed
    const currentEntry = now.currentEntryId === null ? undefined : this.getEntryById(now.currentEntryId)
    const entriesInSession = getEntriesInSession(day, session, entries)

    const rankTypeEntries = this.getRankTypeEntries(entriesInFlight, lift, attemptOneIndexed)

    console.log('rankTypeEntries', rankTypeEntries)

    let numTables = 0
    let totalHeight = 0
    const rankTypeEntriesMax: RankTypeEntriesMax[] = []
    rankTypeEntries.forEach(rankTypeEntry => {
      numTables += 1
      let rankedEntries: Entry[] = []
      if (lift === 'D' && attemptOneIndexed > 1) {
        // table includes all lifters
        rankedEntries = rankTypeEntry.prognosisRankedEntries
      } else {
        // table is just flight lifters
        rankedEntries = rankTypeEntry.interimRankedEntries
      }
      let maxEntries = maxViewableEntries
      if (rankTypeEntry.rankType !== 'SEX_EQUIPMENT_WEIGHTCLASS') {
        // for points, only show as many entries as there are in this session
        maxEntries = entriesInSession.length
      }
      const rankTypeEntryMax = {...rankTypeEntry, maxEntries: maxEntries}
      rankTypeEntriesMax.push(rankTypeEntryMax)
      // allow one extra in case the current lifter is outside the N
      const numEntries = rankedEntries.length > maxEntries ? maxEntries + 1 : rankedEntries.length
      const tableSize = numEntries + 2
      const tableHeight = (tableSize * entryHeight) + ((tableSize - 1) * entryYGap)
      totalHeight += tableHeight
    })
    totalHeight += (numTables - 1) * entryHeight
    let scale = 1
    let top = 0
    let bare = false
    let process = 'FIT_TO_OVERLAY'
    // FIXME: what should height be to miss records and lifterBox
    let maxHeight = 860
    if (this.props.height !== undefined) {
      maxHeight = this.props.height
      process = 'FIT_TO_SCREEN' // fit to screen
    }
    if (this.props.bare !== undefined && this.props.bare === true) {
      process = 'FIT_TO_WIDTH' // fit to screen width
      maxHeight = 1080
      bare = true
    }

    const columnWidth = 66 // 62
    const entryXGap = 2
    const widths = [24, 140, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, columnWidth]
    const titleWidth = getTitleWidth(widths, entryXGap)

    if (process === 'FIT_TO_OVERLAY') {
      // width is always ok, only scale down if height is too high
      // scale down 
      if (totalHeight > maxHeight) {
        scale = maxHeight / totalHeight
        top = (1080 - maxHeight) / 2
      } else {
        top = (1080 - totalHeight) / 2
      }
    } else if (process === 'FIT_TO_SCREEN') {
      // scale to fit screen, but don't exceed width or height
      const xScale = 1920 / titleWidth
      const yScale = 1080 / totalHeight
      scale = Math.min(xScale, yScale)
      console.log(titleWidth, xScale, totalHeight, yScale)
      const scaledHeight = totalHeight * scale
      top = ((1080 - (scaledHeight)) / 2) / scale
    } else if (process === 'FIT_TO_WIDTH') {
      top = 0      
    }

    console.log('scale', scale, totalHeight, top)

    console.log(rankTypeEntries)
    console.log(rankTypeEntriesMax)

    const leaderTables = this.getTables(rankTypeEntriesMax, currentEntry, tableData, top, bare)

    let testImageUrl = ''
    const testImage = getTestImage()
    if (testImage !== undefined && testImage !== '') {
      testImageUrl = "url(./stream/" + testImage + ")"
    }

    if (process === 'FIT_TO_WIDTH') {
      return (
        <div>
          <div style={{transform: `scale(${scale})`}}>
            {leaderTables}
          </div>
        </div>
      )
    }
    return (
      <div style={{position: 'absolute', top: '0px', left: '0px', width: '1920px', height: '1080px', overflow: 'hidden', backgroundImage: testImageUrl}}>
        <div style={{transform: `scale(${scale})`}}>
          {leaderTables}
        </div>
      </div>
    )


  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    meet: state.meet,
    lifting: state.lifting,
    registration: state.registration,
    server: state.server,
    language: state.language,
  };
};

export default connect(mapStateToProps)(ObsLeadersView);

//        <div style={{width: '1920px', height: '1080px'}}>
