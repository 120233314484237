// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Lifting state only tracks manual overrides.
//
// Outside of overrides, the state of the meet is fully-calculated by the LiftingView.
//
// For safety, correctness, and ease of understanding, the state of the meet is
// intentionally *not* stored in the global state. It is continuously recalculated.
//
// Please do not attempt to store meet state in the Redux store!

import {
  UploadReplayAction,
  DeleteReplayAction,
  ResetReplaysAction,
  OverwriteStoreAction,
} from "../types/actionTypes";
import { Replay, ReplaysState} from "../types/stateTypes";
import { checkExhausted } from "../types/utils";

const initialState: ReplaysState = { replays: [] }

type Action = UploadReplayAction | DeleteReplayAction | ResetReplaysAction | OverwriteStoreAction;

const addReplay = (replays: Replay[], replayName: string) => {
  // SIDE-Bettina_Hanlon-S-3-382.mp4
  const nameParts = replayName.split('-')
  const name = nameParts[1].replace('_', ' ')
  const lifterIndex = replays.findIndex(l => l.lifterName === name)
  if (lifterIndex === -1) {
    const replay = {lifterName: name, replays: [replayName]}
    replays.push(replay)
  } else {
    const replay = replays[lifterIndex]
    if (!replay.replays.includes(replayName)) {
      replay.replays.push(replayName)
    }
  }
  return replays
}


export default (state: ReplaysState = initialState, action: Action): ReplaysState => {

//  console.log('replaysReducer', action)

  switch (action.type) {

    case "UPLOAD_REPLAY": 
    {
      let lifters = state.replays.slice();
      lifters = addReplay(lifters, action.replayName)
      return {
        replays: [...lifters],
      };
    }

    case "DELETE_REPLAY": 
    {
      const count = action.count
      const ending = count + '.mp4'
      const updatedReplays: Replay[] = []
      let lifters = state.replays.slice();
      lifters.forEach(lifter => {
        const replays: string[] = []
        lifter.replays.forEach(replay => {
          if (! replay.endsWith(ending)) {
            replays.push(replay)
          }
        })
        if (replays.length > 0) {
          const replay = {lifterName: lifter.lifterName, replays: replays}
          updatedReplays.push(replay)
        }
      })
      return {
        replays: [...updatedReplays],
      };
    }

    case "RESET_REPLAYS": 
    {
      let lifters: Replay[] = []
      action.replays.forEach(replay => {
        lifters = addReplay(lifters, replay)
      })
      return {
        replays: [...lifters],
      };
    }

    // NOTE - the OL store does not contain replays, so don't attempt to overwrite what we have locally
    case "OVERWRITE_STORE":
      if (action.store.replays === undefined) return {...state}
      return {...action.store.replays};

    default:
      checkExhausted(action);
      return state;

  }
};

